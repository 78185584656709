import React, { useState, useEffect, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { UserRole } from '../types/types';
import { useAuthContext } from '../context/AuthContext';

interface PrivateRouteProps {
  children: ReactNode;
  allowedRoles: UserRole[];
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children, allowedRoles }: PrivateRouteProps) => {
  const { userRoles, isAuthenticated, isAuthLoading } = useAuthContext();
  const [isLoading, setIsLoading] = useState(true)
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false)
  }, [])

  useEffect(() => {
    if (!isLoading && allowedRoles && !allowedRoles.some(role => userRoles.includes(role))) {
      navigate("/login");
      return;
    }
  }, [isAuthenticated, userRoles, allowedRoles]);

  if (isLoading) return null;

  return <>{children}</>;
};


export default PrivateRoute;
