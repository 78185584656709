import { API } from "aws-amplify";
import {
  GetProductsPayload,
  ProcessProductPayload,
} from "../types/payload.types";
import {
  FetchCategoriesResponse,
  FetchProductsResponse,
  RequestResponse,
  ResponseType,
} from "../types/response.types";
import { ManagementProduct, Product } from "../types/types";
import { ProcessProductData } from "./Helper";

export const GetProducts = async (
  payload: GetProductsPayload
): Promise<FetchProductsResponse> => {
  try {
    const req = await API.get("management", "/getPendingProducts", {
      queryStringParameters: {
        lastKey: payload.lastKey
      },
    });

    const {Items: productsData} = req

    var processedProducts: ManagementProduct[] = [];
    let failedProducts: any[] = [];
    productsData.forEach((elm: any) => {
      var processed = ProcessProductData(elm);
      if (processed) {
        var _elm = elm;
        _elm.product = processed;
        processedProducts.push(_elm);
      } else failedProducts.push(elm);
    });

    if (failedProducts.length === 0) {
      return {
        SuccessSeverity: ResponseType.SUCCESS,
        products: processedProducts,
        lastEvaluatedKey: req.LastEvaluatedKey,
        hasNextPage: req.hasNextPage,
        count: req.count
      };
    }


    return {
      SuccessSeverity: ResponseType.WARNING,
       products: processedProducts,
        lastEvaluatedKey: req.LastEvaluatedKey,
        hasNextPage: req.hasNextPage,
        count: req.count,
      message: `Failed to processe ${
        failedProducts.length
      } products. Here are the products: ${JSON.stringify(failedProducts)}`,
    };
  } catch (error: any) {
    let message = JSON.stringify(error);
    return {
      SuccessSeverity: ResponseType.ERROR,
      message: `Failed to fetch products: ${message}`,
    };
  }
};

export const GetCategories = async (): Promise<FetchCategoriesResponse> => {
  try {
    var req = await API.get("management", "/getCategories", {});
    return {
      SuccessSeverity: ResponseType.SUCCESS,
      value: req,
    };
  } catch (error: any) {
    return {
      SuccessSeverity: ResponseType.ERROR,
      message: error,
    };
  }
};

export const ProcessProducts = async (
  payload: ProcessProductPayload | ProcessProductPayload[]
): Promise<RequestResponse> => {
  try {
    const items = Array.isArray(payload) ? payload : [payload];

    var req = await API.post("management", "/processProducts", {
      body: {
        items: items,
      },
    });

    if (req.success === false) {
      return {
        SuccessSeverity: ResponseType.ERROR,
        message: req.message,
      };
    }

    if (req.failedProducts && req.failedProducts.length > 0) {
      return {
        SuccessSeverity: ResponseType.WARNING,
        message: `Some products were not processed: ${req.failedProducts.join(
          ", "
        )}`,
      };
    }

    return {
      SuccessSeverity: ResponseType.SUCCESS,
    };
  } catch (error: any) {
    console.log("error: ", error);
    return {
      SuccessSeverity: ResponseType.ERROR,
      message: error,
    };
  }
};
