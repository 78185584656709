import {
  Autocomplete,
  Button,
  Card,
  CardActions,
  CardContent,
  Paper,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { REJECT_REASONS } from "../Modules/Configuration";
import { FormatDate } from "../Modules/Helper";
import "../styling/ProductCard.scss";
import {
  ActionDecision,
  Category,
  CategoryOption,
  ManagementProduct,
  Product,
} from "../types/types";
import UserCard from "./UserCard";

const StyledCard = styled(Card)(({ theme }) => ({
  "&.selected-accept": {
    border: "3px solid green",
  },
  "&.selected-reject": {
    border: "3px solid red",
  },
}));

type ProductCardProps = {
  product: ManagementProduct;
  inQueue: (id: string) => boolean;
  inQueueActionType: (id: string) => ActionDecision | undefined;
  handleAccept: (productId: string, cat: string, brand: string) => void;
  handleReject: (id: string) => void;
  handleUnselect: (id: string) => void;
  rejectReasons: Record<string, string>;
  handleRejectReason: (id: string, reason: string) => void;
  categories: Category[];
};

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
}));

function renderOptions(category: Category, numbering = "1"): CategoryOption[] {
  const options: CategoryOption[] = [];
  options.push({ level: numbering.split(".").length - 1, category, numbering });
  category.subcategories.forEach((subcategory, index) => {
    options.push(...renderOptions(subcategory, `${numbering}.${index + 1}`));
  });
  return options;
}

function buildCategoryOptions(categories: Category[]): CategoryOption[] {
  let numbering = 0;
  return categories.flatMap((category) => {
    numbering += 1;
    return renderOptions(category, numbering.toString());
  });
}

const ErrorMessage = styled(Tooltip)(({ theme }) => ({
  color: "red",
  cursor: "pointer",
}));

const ProductCard: React.FC<ProductCardProps> = ({
  product,
  inQueue,
  inQueueActionType,
  handleAccept,
  handleReject,
  handleUnselect,
  rejectReasons,
  handleRejectReason,
  categories,
}) => {
  const PRODUCT_CATEGORIES_OPTIONS: CategoryOption[] =
    buildCategoryOptions(categories);
  const [userModalOpen, setUserModalOpen] = useState(false);

  //STATE VARIABLES
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [isBrandRefinedSelected, setIsBrandRefinedSelected] = useState(false);
  const [rejectReason, setRejectReason] = useState("")
  ///
  const onModalOpen = () => setUserModalOpen(true);
  const onModalClose = () => setUserModalOpen(false);

  const buildRefinedCategories = (cats: string[]): CategoryOption[] => {
    var output: CategoryOption[] = [];
    cats.forEach((f) => {
      const found = PRODUCT_CATEGORIES_OPTIONS.find(
        (x) => x.category.catSk === f
      );
      if (found) output.push(found);
    });
    return output;
  };


  const refinedCategories = product.refiner.success && product.refiner.message?.categories
    ? buildRefinedCategories(product.refiner.message.categories)
    : [];

  const brands = product.refiner.success && product.refiner.message?.brands ? product.refiner.message.brands : [];

  const handleCategoryChange = (
    value: string | undefined,
    comingFromRefined = false
  ) => {
    if (!value) {
      setIsBrandRefinedSelected(false);
      setCategory("");
    } else {
      if (comingFromRefined) {
        setIsBrandRefinedSelected(true);
      }
      setCategory(value);
    }
  };

  const _handleRejectReason = (value: string) => {
    setRejectReason(value)
    handleRejectReason(product.PK, value)
  }

  const handleBrandChange = (value: string | undefined | null) => {
    setBrand(value ?? "");
  };

  return (
    <>
      <UserCard
        user={product.product.seller}
        onModalClose={onModalClose}
        userModalOpen={userModalOpen}
      />
      <StyledCard
        className={
          inQueue(product.PK) ? `selected-${inQueueActionType(product.PK)}` : ""
        }
      >
        <Carousel showThumbs={false}>
          {product.product.images.map((imgUrl, index) => (
            <div key={index}>
              <img src={imgUrl} style={{ width: "200px", height: "300px" }} />
            </div>
          ))}
        </Carousel>

        <CardContent>
          <StyledPaper>
            <Typography variant="h5" fontWeight="bold">
              {product.product.title}
            </Typography>
            {/* <Typography variant="subtitle1" fontWeight="medium">Seller: {product.seller}</Typography> */}
            <Typography variant="subtitle1" fontWeight="medium">
              <Button variant="outlined" onClick={onModalOpen}>
                Seller Details
              </Button>
            </Typography>

            <Typography variant="subtitle1" fontWeight="medium">
              <a href={product.product.location.locationLink} target="_blank">
                Location
              </a>
            </Typography>
            <Typography variant="subtitle2" fontWeight="medium">
              Time: {FormatDate(product.product.createdAt)}
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              Price: AED{product.product.price.toFixed(3)}
            </Typography>
          </StyledPaper>
          {!product.refiner.success && (
            <ErrorMessage title={product.refiner.message} placement="top">
              <Typography>Refiner failed</Typography>
            </ErrorMessage>
          )}

          {product.refiner.success && (
            <Autocomplete
              options={refinedCategories}
              size="small"
              getOptionLabel={(option) =>
                option.numbering + " " + option.category.name
              }
              renderOption={(props, option) => (
                <li {...props}>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    style={{
                      marginLeft: `${option.level * 2}px`,
                      fontSize: "15px",
                    }}
                  >
                    {option.numbering} {option.category.name}
                  </Typography>
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Refined Categories"
                  variant="outlined"
                  color="success"
                />
              )}
              onChange={(_, value) =>
                handleCategoryChange(value?.category.catSk, true)
              }
              color="success"
              disabled={rejectReason.length > 0}
            />
          )}
          <Autocomplete
            options={PRODUCT_CATEGORIES_OPTIONS}
            size="small"
            getOptionLabel={(option) =>
              option.numbering + " " + option.category.name
            }
            renderOption={(props, option) => (
              <li {...props}>
                <Typography
                  variant="subtitle1"
                  color="text.secondary"
                  style={{
                    marginLeft: `${option.level * 2}px`,
                    fontSize: "15px",
                  }} // You can adjust the multiplier to get the desired indentation
                >
                  {option.numbering} {option.category.name}
                </Typography>
              </li>
            )}
            disabled={isBrandRefinedSelected || rejectReason.length > 0}
            renderInput={(params) => (
              <TextField {...params} label="Category" variant="outlined" />
            )}
            onChange={(_, value) => handleCategoryChange(value?.category.catSk)}
          />
          <Autocomplete
            freeSolo
            options={brands}
            size="small"
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Brands"
                variant="outlined"
                placeholder="Add a brand"
              />
            )}
            onInputChange={(_, value) => handleBrandChange(value)}
            disabled={rejectReason.length > 0}
          />

          <Autocomplete
            freeSolo
            size="small"
            options={REJECT_REASONS}
            getOptionLabel={(option) => option}
            onInputChange={(_, value) => {
              _handleRejectReason(value)
            }}
            renderInput={(params) => (
              <TextField {...params} label="Reject reason" variant="outlined" />
            )}
            value={rejectReasons[product.PK] || ""}
          />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="success"
            onClick={() => handleAccept(product.PK, category, brand)}
            disabled={inQueue(product.PK) || !brand || !category || rejectReason.length > 0}
          >
            Accept
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={() => handleReject(product.PK)}
            disabled={inQueue(product.PK) || !(product.PK in rejectReasons)}
          
          >
            Reject
          </Button>
          {inQueue(product.PK) && (
            <Button
              variant="outlined"
              color="error"
              onClick={() => handleUnselect(product.PK)}
              className="solid"
            >
              Unselect
            </Button>
          )}
        </CardActions>
      </StyledCard>
    </>
  );
};

export default ProductCard;
