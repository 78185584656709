import React, { createContext, useContext, useState } from 'react';
import { PanelName } from '../types/types';

// Define the type for the context value
interface PanelContextValue {
  activePanel: PanelName;
  switchPanel: (panelName: PanelName) => void;
}

// Panel context
const defaultPanelContextValue: PanelContextValue = {
  activePanel: PanelName.PRODUCT_MANAGEMENT, // Set the default active panel name here
  switchPanel: () => {}, // A dummy function for the default context
};

const PanelContext = createContext<PanelContextValue>(defaultPanelContextValue);

// Panel provider
export function PanelProvider({ children }: { children: React.ReactNode }) {
  const [activePanel, setActivePanel] = useState<PanelName>(PanelName.PRODUCT_MANAGEMENT);

  const switchPanel = (panelName: PanelName) => {
    setActivePanel(panelName);
  };

  return (
    <PanelContext.Provider value={{ activePanel, switchPanel }}>
      {children}
    </PanelContext.Provider>
  );
}

// Custom hook to use the PanelContext
export function usePanelContext() {
  return useContext(PanelContext);
}
