import { Alert, Button, Snackbar } from '@mui/material';
import { API, Storage } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import ReactJson from 'react-json-view';
import { LoadingCircle } from '../../../Components/LoadingCircle';

const CategoryManagement = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [jsonFileData, setJsonFileData] = useState<any>({ categories: [] });
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState<'success' | 'error'>('error');
  const [open, setOpen] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const result = await Storage.get('Configuration/categories.json', {
          download: true,
          validateObjectExistence: true,
          level: "public"
        });
        const reader = new FileReader();
        reader.onload = function () {
          if (typeof reader.result === 'string') {
            const data = JSON.parse(reader.result);
            setJsonFileData(data);
          } else {
            throw new Error('File contents could not be read.');
          }
            setIsLoading(false);
        }
        if (result.Body === undefined) throw new Error('File contents could not be read.');
        reader.readAsText(result.Body);
      } catch (error: any) {
        setSeverity('error');
        setMessage('Error fetching file: ' + error.message);
        setOpen(true);
        setIsLoading(false);
      }
    })()
  }, []);

  const handleClose = (event: any) => {
    if (event === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleSendData = async () => {
    setIsProcessing(true);
    try {
      const response = await API.post('management', '/updateCategories', {
        body: jsonFileData,
      });
      setSeverity('success');
      setMessage('Data inserted successfully!');
    } catch (error) {
      setSeverity('error');
      setMessage('An error occurred while processing the data. Please try again.');
      console.error(error);
    }
    setIsProcessing(false);
    setOpen(true);
  };

  if (isLoading) {
    <LoadingCircle />
  }

  return (
    <React.Fragment>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
          <div style={{ flex: 1 }}>
            <ReactJson src={jsonFileData} />
          </div>
        </div>
        <Button variant="contained" color="primary" onClick={handleSendData} disabled={isProcessing}>
          {isProcessing ? 'Processing...' : 'Send Data to DynamoDB'}
        </Button>
      </div>
    </React.Fragment>
  );
};

export default CategoryManagement;
