import { ManagementProduct } from "./types";

export enum ResponseType {
    SUCCESS = "success",
    ERROR = "error",
    WARNING = "warning" //some worked some not
}

interface BaseResponse {
    SuccessSeverity: ResponseType;
}

// Error Response
interface ErrorResponse extends BaseResponse {
    SuccessSeverity: ResponseType.ERROR;
    message: string; 
}

// Success Response
interface SuccessResponse extends BaseResponse {
    SuccessSeverity: ResponseType.SUCCESS;
}

// Warning Response
interface WarningResponse extends BaseResponse {
    SuccessSeverity: ResponseType.WARNING;
    message: string;
}

//START - Fetch Products
interface FetchProductsSuccessResponse extends SuccessResponse {
    products: ManagementProduct[];
    count: number;
    lastEvaluatedKey: string | undefined;
    hasNextPage: boolean;
}

interface FetchProductsErrorResponse extends ErrorResponse {
    
}

interface FetchProductsWarningResponse extends WarningResponse {
    products: ManagementProduct[];
    count: number;
    lastEvaluatedKey: string | undefined;
    hasNextPage: boolean;
}
//END - Fetch Products


//START - Fetch Categories (will change them later)

interface FetchCategoriesSuccessResponse extends SuccessResponse {
    value: any;
}

interface FetchCategoriesErrorResponse extends ErrorResponse {
    
}

interface FetchCategoriesWarningResponse extends WarningResponse {
    value: any;
}

//END - Fetch Categories

// Union type for fetch products
export type FetchProductsResponse = FetchProductsSuccessResponse | FetchProductsErrorResponse | FetchProductsWarningResponse;
export type FetchCategoriesResponse = FetchCategoriesSuccessResponse | FetchCategoriesErrorResponse | FetchCategoriesWarningResponse
export type RequestResponse = ErrorResponse | SuccessResponse | WarningResponse;