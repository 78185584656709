import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { data } from "./testData";
import TextareaAutosize from '@mui/material/TextareaAutosize';
import "../styling/LogViewer.scss"

const LogConsole = () => {
  const [value, setValue] = useState("")
  return (
    <div className="log-viewer">
      <TextareaAutosize
        style={{ height: '99%', resize: 'none', width: "100%" }}
        value={"1232131231"}
      />
    </div>
  );
};

export default LogConsole;
