import { Pagination } from '@mui/lab';
import {
    Box,
    CircularProgress,
    Grid,
    MenuItem,
    Select
} from '@mui/material';
import { styled } from '@mui/system';
import React, { useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NoItem from "../../../Components/NoItem";
import ProductCard from '../../../Components/ProductCard';
import { ActionDecision, ActionQueue, Category } from '../../../types/types';

const LoadingScreen = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
});


type SelectedViewProps = {
    products: ActionQueue[];
    itemsPerPage: number;
    inQueueActionType: (id: string) => ActionDecision | undefined;
    handleAccept: (productId: string, cat: string, brand: string) => void;
    handleReject: (id: string) => void;
    handleUnselect: (id: string) => void;
    rejectReasons: Record<string, string>;
    handleRejectReason: (id: string, reason: string) => void;
    categories: Category[];
}


const SelectedView = ({
    products,
    itemsPerPage,
    inQueueActionType,
    handleAccept,
    handleReject,
    handleUnselect,
    rejectReasons,
    handleRejectReason,
    categories }: SelectedViewProps) => {
    const [isLoading, setIsLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [maxPages, setMaxPages] = useState(Math.ceil(products.length / itemsPerPage))
    const [acceptedProductsCount, setAcceptedProductsCount] = useState(0)
    const [rejectedProductsCount, setRejectedProductsCount] = useState(0)

    // New state for filter
    const [filter, setFilter] = useState<"all" | "accepted" | "rejected">("all");

    // New handler for filter changes
    const handleFilterChange = (event: any) => {
        setFilter(event.target.value);
    };

    // Function to get the filtered products
    const getFilteredProducts = () => {
        switch (filter) {
            case "accepted":
                return products.filter(action => action.action === ActionDecision.ACCEPT);
            case "rejected":
                return products.filter(action => action.action === ActionDecision.REJECT);
            case "all":
            default:
                return products;
        }
    };

    // Use the getFilteredProducts function for displaying the products
    const displayedProducts = getFilteredProducts();

    useEffect(() => {
        setIsLoading(false)
    }, [])

    useEffect(() => {
        //very stupid of me i know
        setAcceptedProductsCount(products.filter(action => action.action === ActionDecision.ACCEPT).length)
        setRejectedProductsCount(products.filter(action => action.action === ActionDecision.REJECT).length)
    }, [products])



    const handleChangePage = (event: any, value: number) => {
        setPage(value);
    };

    if (isLoading) {
        return (
            <LoadingScreen>
                <CircularProgress />
            </LoadingScreen>
        );
    }

    if (products.length === 0) {
        return (
            <NoItem />
        )
    }

    return (
        <React.Fragment>
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                <Box sx={{ flexGrow: 1, m: 2 }}>
                    <Select
                        value={filter}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="accepted">Accepted ({acceptedProductsCount})</MenuItem>
                        <MenuItem value="rejected">Rejected ({rejectedProductsCount})</MenuItem>
                    </Select>
                    <Grid container spacing={2}>
                        {displayedProducts
                            .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                            .map(action => {
                                var product = action.item;
                                if (product == undefined) return <></>
                                return (
                                    <Grid item xs={10} sm={6} md={4} lg={3} xl={2} key={product.PK}>
                                        <ProductCard
                                            product={product}
                                            inQueue={(_) => true}
                                            inQueueActionType={inQueueActionType}
                                            handleAccept={handleAccept}
                                            handleReject={handleReject}
                                            handleUnselect={handleUnselect}
                                            rejectReasons={rejectReasons}
                                            handleRejectReason={handleRejectReason}
                                            categories={categories}
                                        />
                                    </Grid>
                                )
                            }
                            )}
                    </Grid>
                </Box>
                {
                    maxPages > 1 && (

                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Pagination
                                count={maxPages}
                                page={page}
                                onChange={handleChangePage}
                                variant="outlined"
                                color="primary"
                                style={{ marginTop: '20px' }}
                                size="large"

                            />
                        </div>
                    )
                }
            </div>
        </React.Fragment>
    );
}

export default SelectedView;
