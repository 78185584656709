import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogTitle, Snackbar, Alert, ButtonGroup } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import '../styling/Navbar.scss';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { AuthContext, useAuthContext } from '../context/AuthContext';
import { usePanelContext } from '../context/PanelContext';
import { PanelName, UserRole } from '../types/types';
import ClassIcon from '@mui/icons-material/Class';

const Navbar = () => {
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const { isAuthenticated, userRoles } = useAuthContext();
  const { activePanel, switchPanel } = usePanelContext();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (answer: string) => {
    setOpen(false);
    if (answer === 'yes') {
      Auth.signOut();
    }
  };

  return (
    <div className="navbar">
      <div className="navbar-content">
        {userRoles.includes(UserRole.SUPERADMIN) && (
          <ButtonGroup orientation='vertical'>
            <Button className="navbar-button" variant="contained" onClick={() => navigate('/panel/category-management')} startIcon={<ClassIcon fontSize="large" />}>
              Category Management
            </Button>
          </ButtonGroup>
        )}
        <Button className="navbar-button" variant="contained" onClick={() => navigate('/panel/product-management')} startIcon={<ShoppingCartIcon fontSize="large" />}>
          Product Management
        </Button>
        <div className='navbar-footer'>
          <Button startIcon={<PowerSettingsNewIcon fontSize="large" />} color="error" variant="outlined" onClick={handleClickOpen}>
            Sign out
          </Button>
        </div>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to sign out?"}</DialogTitle>
        <DialogActions>
          <Button onClick={() => handleClose('no')} color="primary">
            No
          </Button>
          <Button onClick={() => handleClose('yes')} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {!isAuthenticated && (
        <Snackbar
          open={true}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          onClose={() => {
            navigate('/');
          }}
        >
          <Alert severity="error" sx={{ width: '100%' }}>
            Logged out.....
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default Navbar;
