export enum UserRole {
  SUPERADMIN = "superadmin",
  STAFF = "staff",
}

export enum PanelName {
  USER_MANAGEMENT = "user-management",
  PRODUCT_MANAGEMENT = "product-management",
}

export enum ActionDecision {
  ACCEPT = "accept",
  REJECT = "reject",
}

//could not find a better naming for it
export type ManagementProduct = {
  PK: string;
  SK: string;
  product: Product;
  LastModifiedDate: Date;
  CreatedAt: Date;
  GSI1PK: string;
  refiner: ProductRefinerResponse;
  user: User;
};

export type Product = {
  PK: string;
  SK: string;
  title: string;
  seller: User;
  location: UserLocation;
  createdAt: Date;
  lastModifiedAt: Date | null;
  condition: "New" | "Old";
  description?: string;
  images: string[];
  price: number;
  productUsage?: string;
  status: ProductStatus;
};

export type ProductRefinerResponse =
  | {
      success: false;
      message: string;
    }
  | {
      success: true;
      message: ProductRefiner;
    };

type ProductRefiner = {
  categories: string[];
  brands: string[];
};

export class UserLocation {
  coordinates: number[] | [number, number];
  name: string;

  constructor(name: string, coordinates: number[] | [number, number]) {
    this.name = name;
    this.coordinates = coordinates;
  }

  get locationLink(): string {
    return `http://www.google.com/maps/place/${this.coordinates[0]},${this.coordinates[1]}`;
  }
}

export enum ProductStatus {
  PENDING,
  ACCEPTED,
  REJECTED,
}

export type User = {
  PK: string;
  SK: string;
  sub: string;
  fullName: string;
  email: string;
  joinDate: Date;
  username: string;
  phoneNumber: string;
  lastModifiedDate: Date;
  picture: string;
  gender: string;
};

export type ActionQueue = {
  item: ManagementProduct; //maybe useful
} & (
  | {
      action: ActionDecision.ACCEPT;
      categoryPk: string;
      brand: string;
    }
  | {
      action: ActionDecision.REJECT;
      rejectReason: string;
    }
);

interface Subcategory {
  name: string;
  catSk: string;
  subcategories: Subcategory[];
}

export type Category = Subcategory;
export interface CategoryOption {
  level: number;
  category: Category;
  numbering: string;
}
