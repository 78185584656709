import React, {useEffect} from 'react'
import { User } from '../types/types'
import {
    Autocomplete,
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    Grid,
    Paper,
    TextField,
    Tooltip,
    Typography,
  } from "@mui/material";
  import Modal from "@mui/material/Modal";
import { FormatDate } from '../Modules/Helper';

type UserCardProps = {
    user: User;
    userModalOpen: boolean;
    onModalClose: () => void;
}

const modalStyle = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

const UserCard = ({user, userModalOpen, onModalClose} : UserCardProps) => {
  return (
    <Modal open={userModalOpen} onClose={onModalClose}>
        <Box sx={modalStyle}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={4}>
              <Avatar
                alt={user.fullName}
                // src={dummySeller.profilePicture}
                src="https://via.placeholder.com/150"
                style={{ width: 80, height: 80 }}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography variant="h6" component="h2">
                {user.fullName}
              </Typography>
              <Typography variant="body1">
                Email: {user.email}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Joined: {FormatDate(user.joinDate)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
  )
}

export default UserCard