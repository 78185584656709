import { ThemeProvider, createTheme } from "@mui/material/styles";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import NoMatch from "./Components/NoMatch";
import PrivateRoute from "./Components/PrivateRoute";
import Login from "./Pages/Login/Login";
import CategoryManagement from "./Pages/Panel/Admin/CategoryManagement";
import Panel from "./Pages/Panel/Panel";
import ProductManagement from "./Pages/Panel/Staff/ProductManagement";
import { AuthProvider, useAuthContext } from "./context/AuthContext";
import { PanelProvider } from "./context/PanelContext";
import "./styling/App.css";
import { UserRole } from "./types/types";

const theme = createTheme({
  palette: {},
});

function MainRoutes() {
  const { isAuthenticated, isAuthLoading } = useAuthContext();

  return (
    <Routes>
      <Route
        path="/"
        element={
          isAuthenticated ? <Navigate to="/panel" /> : <Navigate to="/login" />
        }
      />
      <Route path="/login" element={<Login />} />
      <Route
        path="/panel"
        element={
          <PrivateRoute allowedRoles={[UserRole.STAFF]}>
            <Panel />
          </PrivateRoute>
        }
      >
        <Route
          path="category-management/"
          element={
            <PrivateRoute allowedRoles={[UserRole.SUPERADMIN]}>
              <CategoryManagement />
            </PrivateRoute>
          }
        />
        <Route
          path="product-management/"
          element={
            <PrivateRoute allowedRoles={[UserRole.STAFF]}>
              <ProductManagement />
            </PrivateRoute>
          }
        />
      </Route>
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <PanelProvider>
            <MainRoutes />
          </PanelProvider>
        </AuthProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
