import { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Navbar from '../../Components/Navbar';
import { usePanelContext } from '../../context/PanelContext';
import '../../styling/Panel.scss';
import CategoryManagement from './Admin/CategoryManagement';
import ProductManagement from './Staff/ProductManagement';

function Panel() {
  const [isLoading, setIsLoading] = useState(true);
  const { activePanel } = usePanelContext();
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(false);
    navigate('/panel/product-management'); 
  }, []); // Empty dependency array to trigger navigation only once when component mounts

  if (isLoading) {
    return <>Loading</>;
  }

  return (
    <div className='panel'>
        <Navbar />
      <main className='main-content'>
        {/* Add position: relative and zIndex to main-content */}
        <Routes>
          <Route index path='/product-management' element={<ProductManagement />} />
          <Route path='/category-management' element={<CategoryManagement />} />
        </Routes>
      </main>
    </div>
  );
}

export default Panel;
