import React from "react";
import "../styling/NoItem.scss"

const App = () => {
    return (
        <div className="noItem">
            <p>No item found</p>
        </div>
    )
}


export default App;