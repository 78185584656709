import RefreshIcon from "@mui/icons-material/Refresh";
import { Box, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoadingCircle } from "../../../Components/LoadingCircle";
import LogConsole from "../../../Components/LogConsole";
import _SegmentedControl from "../../../Components/SegmentedControl";
import { ShowAlert } from "../../../Modules/Helper";
import {
  GetCategories,
  GetProducts,
  ProcessProducts,
} from "../../../Modules/RequestFactory";
import { ProcessProductPayload } from "../../../types/payload.types";
import { ResponseType } from "../../../types/response.types";
import {
  ActionDecision,
  ActionQueue,
  Category,
  ManagementProduct,
} from "../../../types/types";
import AllView from "./AllView";
import SelectedView from "./SelectedView";

type ViewType = "selected" | "all" | "logs";

const ProductManagement = () => {
  const itemsPerPage = 12;
  const [actionQueues, setActionQueues] = useState<ActionQueue[]>([]);
  const [rejectReasons, setRejectReasons] = useState<Record<string, string>>(
    {}
  );
  const [view, setView] = useState<ViewType>("all"); // 'all' or 'selected'

  //Refs-start
  const allProductsRef = useRef(null);
  const selectedProductsRef = useRef(null);
  const logsRef = useRef(null);
  const controlRef = useRef(null);
  //Refs-end

  //LOADING VARIABLES
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFetchingProducts, setIsFetchingProducts] = useState(false); //for fetching products
  const [isProcessingProducts, setIsProcessingProducts] = useState(false); //for processing products
  const [isFetchingCategories, setIsFetchingCategories] = useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = useState(false); //for pagination
  const anyFetchingHappening =
    isPageLoading ||
    isFetchingProducts ||
    isProcessingProducts ||
    isFetchingCategories;
  //

  //API VARIABLES
  const [products, setProducts] = useState<ManagementProduct[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [lastEvalutedKey, setLastEvalutedKey] = useState<
    string | null | undefined
  >("");
  const hasNextPage = Boolean(lastEvalutedKey) && lastEvalutedKey !== "";
  //

  const addQueue = (action: ActionQueue) => {
    setActionQueues((prev) => [...prev, action]);
  };

  const removeQueue = (PK: string) => {
    setActionQueues((prev) => prev.filter((x) => x.item.PK != PK));
  };

  const inQueue = (PK: string): boolean =>
    actionQueues.some((p) => p.item.PK == PK);
  const inQueueActionType = (PK: string): ActionDecision | undefined =>
    actionQueues.find((p) => p.item.PK == PK)?.action;

  const fetchProducts = async () => {
    if (!isFetchingProducts && !isFetchingNextPage) {
      //then fetching next page
      if (hasNextPage) setIsFetchingNextPage(true);
      else {
        //clear action for new fetching
        setIsFetchingProducts(true);
        setActionQueues([]);
        setRejectReasons({})
      }

      let productsData = await GetProducts({
        lastKey: lastEvalutedKey,
      });

      if (productsData.SuccessSeverity !== ResponseType.ERROR) {
        setProducts(productsData.products);
        setLastEvalutedKey(productsData.lastEvaluatedKey ?? "");
      }

      if (hasNextPage) setIsFetchingNextPage(false);
      else setIsFetchingProducts(false);

      ShowAlert(productsData, {
        successMessage: "Fetched products",
        warningMessage: "Fetch products, but some failed.. contact admin",
        errorMessage: "Failed to fetch products: ",
      });
    }
  };

  useEffect(() => {
    setIsPageLoading(false);
    (async () => {
      setIsFetchingCategories(true);
      var req = await GetCategories();
      if (req.SuccessSeverity === ResponseType.SUCCESS) {
        setCategories(req.value);
      } else {
        toast.error("Failed to get categories...", {
          autoClose: 10000,
        });
      }
    })();
  }, []);

  useEffect(() => {
    if (categories.length > 0) {
      (async () => {
        await fetchProducts();
        setIsFetchingCategories(false);
      })();
    }
  }, [categories]);

  const handleAccept = (productId: string, cat: string, brand: string) => {
    // Implement your logic here
    addQueue({
      item: products.find(
        (x) => x.product.PK == productId
      ) as ManagementProduct,
      action: ActionDecision.ACCEPT,
      categoryPk: cat,
      brand: brand,
    });
  };

  const handleReject = (productId: string) => {
    // Implement your logic here
    addQueue({
      action: ActionDecision.REJECT,
      rejectReason: rejectReasons[productId],
      item: products.find(
        (x) => x.product.PK == productId
      ) as ManagementProduct,
    });
  };

  const handleUnselect = (productId: string) => {
    removeQueue(productId);
  };

  const handleAcceptSelected = async () => {
    if (!isProcessingProducts) {
      let payload: ProcessProductPayload[] = [];
      payload = actionQueues.map((x) => {
        let _payload = {
          webPk: x.item.PK,
          webSk: x.item.SK,
          mobilePk: x.item.product.PK,
          mobileSk: x.item.product.SK,
        };
        if (x.action === ActionDecision.ACCEPT) {
          return {
            ..._payload,
            action: x.action,
            categoryPk: x.categoryPk,
            brand: x.brand,
          };
        }

        return {
          ..._payload,
          action: x.action,
          rejectReason: x.rejectReason,
        };
      });

      setIsProcessingProducts(true);
      const response = await ProcessProducts(payload);
      setIsProcessingProducts(false);

      if (response.SuccessSeverity !== ResponseType.ERROR) {
        setActionQueues([]);
        const webPksToDelete = new Set(payload.map((x) => x.webPk));
        setProducts((x) => x.filter((p) => !webPksToDelete.has(p.PK)));
      }

      ShowAlert(response, {
        successMessage: "Processed products",
        warningMessage:
          "Some products were processed, some not.. contact admin",
        errorMessage: "Failed to process products",
      });
    }
  };

  const handleRejectReason = (productId: string, reason: string | null) => {
    if (reason == null || reason == "") {
      const newRejectReasons = { ...rejectReasons }; // Creating a copy of the reject reasons
      delete newRejectReasons[productId]; // Removing the reject reason for the product
      setRejectReasons(newRejectReasons); // Updating the state
    } else {
      setRejectReasons((prevReasons) => ({
        ...prevReasons,
        [productId]: reason,
      }));
    }
  };

  function strToViewType(str: string): ViewType {
    switch (str) {
      case "all":
        return "all";
      case "selected":
        return "selected";
      case "logs":
        return "logs";
      default:
        throw new Error(`Invalid ViewType value: ${str}`);
    }
  }

  if (isPageLoading) {
    return <LoadingCircle />;
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Box sx={{ flexGrow: 1, m: 2 }}>
        <_SegmentedControl
          segments={[
            {
              label: "All Products",
              value: "all",
              ref: allProductsRef,
            },
            {
              label: `Selected Products (${actionQueues.length})`,
              value: "selected",
              ref: selectedProductsRef,
            },
            {
              label: "Logs",
              value: "logs",
              ref: logsRef,
            },
          ]}
          defaultIndex={0}
          controlRef={controlRef}
          callback={(val: string, _) => setView(strToViewType(val))}
          name="view-options"
          leftSideItem={
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={fetchProducts}
                style={{ marginRight: "8px" }} // Space between button and the segmented control
                disabled={anyFetchingHappening}
              >
                <RefreshIcon />
              </Button>
            </>
          }
        />
        <div style={{ display: view == "all" ? "flex" : "none" }}>
          {anyFetchingHappening ? (
            <LoadingCircle />
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexGrow: 1,
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                <AllView
                  products={products}
                  inQueue={inQueue}
                  inQueueActionType={inQueueActionType}
                  handleAccept={handleAccept}
                  handleReject={handleReject}
                  handleUnselect={handleUnselect}
                  rejectReasons={rejectReasons}
                  handleRejectReason={handleRejectReason}
                  categories={categories}
                />
                {hasNextPage && (
                  <Button
                    variant="contained"
                    color="success"
                    onClick={fetchProducts}
                    disabled={anyFetchingHappening || isFetchingNextPage}
                  >
                    Fetch more products
                  </Button>
                )}
              </div>
            </>
          )}
        </div>

        <div style={{ display: view == "selected" ? "flex" : "none" }}>
          <SelectedView
            products={actionQueues}
            itemsPerPage={itemsPerPage}
            inQueueActionType={inQueueActionType}
            handleAccept={handleAccept}
            handleReject={handleReject}
            handleUnselect={handleUnselect}
            rejectReasons={rejectReasons}
            handleRejectReason={handleRejectReason}
            categories={categories}
          />
        </div>

        <div style={{ display: view == "logs" ? "flex" : "none" }}>
          <LogConsole />
        </div>

        {actionQueues.length > 0 && (
          <div
            style={{
              position: "fixed",
              right: 20,
              bottom: 20,
              backgroundColor: "#fff",
              padding: 20,
              borderRadius: 8,
            }}
          >
            <p>{actionQueues.length} products modified</p>
            <Button
              variant="contained"
              color="success"
              onClick={handleAcceptSelected}
              disabled={anyFetchingHappening}
            >
              Process products
            </Button>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
};

export default ProductManagement;
