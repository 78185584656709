import React from "react";
import "../styling/NoMatch.scss"

const App = () => {

    return (
        <div className="noMatch">
            <p>404</p>
        </div>
    )
}


export default App;