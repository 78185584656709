import { Pagination } from "@mui/lab";
import { Box, Card, CircularProgress, Grid, Paper } from "@mui/material";
import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import NoItem from "../../../Components/NoItem";
import { ActionDecision, Category, ManagementProduct, Product } from "../../../types/types";
import ProductCard from "../../../Components/ProductCard";

const LoadingScreen = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
});

type AllViewProps = {
  products: ManagementProduct[];
  inQueue: (id: string) => boolean;
  inQueueActionType: (id: string) => ActionDecision | undefined;
  handleAccept: (productId: string, cat: string, brand: string) => void;
  handleReject: (id: string) => void;
  handleUnselect: (id: string) => void;
  rejectReasons: Record<string, string>;
  handleRejectReason: (id: string, reason: string) => void;
  categories: Category[];
};

const AllView = ({
  products,
  inQueue,
  inQueueActionType,
  handleAccept,
  handleReject,
  handleUnselect,
  rejectReasons,
  handleRejectReason,
  categories,
}: AllViewProps) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  if (isLoading) {
    return (
      <LoadingScreen>
        <CircularProgress />
      </LoadingScreen>
    );
  }

  if (products.length === 0) {
    return <NoItem />;
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <Box sx={{ flex: 1, m: 2 }}>
          <Grid container spacing={2}>
            {products
              .map((product) => {
                if (product == undefined) return <></>;
                return (
                  <Grid
                    item
                    xs={10}
                    sm={6}
                    md={4}
                    lg={3}
                    xl={2}
                    key={product.PK}
                  >
                    <ProductCard
                      product={product}
                      inQueue={inQueue}
                      inQueueActionType={inQueueActionType}
                      handleAccept={handleAccept}
                      handleReject={handleReject}
                      handleUnselect={handleUnselect}
                      rejectReasons={rejectReasons}
                      handleRejectReason={handleRejectReason}
                      categories={categories}
                    />
                  </Grid>
                );
              })}
          </Grid>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default AllView;
