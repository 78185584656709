import {
    CircularProgress
} from '@mui/material';
import { styled } from '@mui/system';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const LoadingScreen = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    flex: 1,
  });


export const LoadingCircle = () => (
    <LoadingScreen>
        <CircularProgress />
    </LoadingScreen>
)