import React, { createContext, useContext } from 'react';
import { useAuth } from '../Hooks/useAuth';
import { UserRole } from '../types/types';

type AuthContextType = {
  isAuthenticated: boolean;
  userRoles: UserRole[];  
  signIn: (username: string, password: string) => Promise<any>;
  user: any;
  isAuthLoading: boolean;
};

// Create a new context for authentication data
export const AuthContext = createContext<AuthContextType>({
  isAuthenticated: false,
  userRoles: [], // use empty array for default roles
  signIn: () => Promise.reject("signIn function not implemented"),
  user: {},
  isAuthLoading: true
});

export function AuthProvider({ children }: { children: any }) {
  const auth = useAuth();

    return (
        <AuthContext.Provider value={auth}>
            {children}
        </AuthContext.Provider>
    )
}

// Custom hook that lets you quickly use this authentication data
export function useAuthContext() {
  return useContext(AuthContext);
}
