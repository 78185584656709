import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../styling/Login.scss"
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Snackbar, Alert, LinearProgress } from '@mui/material';
import { AuthContext, useAuthContext } from '../../context/AuthContext';
import { useAuth } from '../../Hooks/useAuth';
import { Auth } from 'aws-amplify';
const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& input': {
            fontSize: '1.5rem',
            color: 'white',
            width: "400px"
          },
          '& label.Mui-focused': {
            color: 'white',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#009E4E',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#009E4E',
            },
            '&:hover fieldset': {
              borderColor: '#009E4E',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#009E4E',
            },
          },
        },
      },
    },
  },
});


const App = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [name, setName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [isNewPasswordRequired, setNewPasswordRequired] = useState(false);
  const { isAuthenticated, signIn, user } = useAuthContext();

  const navigate = useNavigate();

  const isValidLogin = username.length >= 5 && password.length >= 8;
  const isValidNewPassword = newPassword.length >= 8 && newPassword === confirmNewPassword && name.length >= 3;

  const handleLogin = async () => {
    setIsLoading(true);
    setError("");
    try {
      const user = await signIn(username, password)
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setNewPasswordRequired(true);
      }
      else {
        if (!isAuthenticated) {
          setError("Unkown error")
        }
      }
    } catch (error) {
      setError("Invalid username or password.");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (isAuthenticated) navigate("/panel")
  }, [isAuthenticated])

  const handleNewPasswordSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match.");
      return;
    }
    if (name.length < 3) {
      setError("Name must be at least 3 characters.");
      return;
    }

    setIsLoading(true);
    try {
      if (user) {
        await Auth.completeNewPassword(user, newPassword, { name });
        navigate('/panel');
      }
    } catch (error) {
      setError("Error updating password.");
    }
    setIsLoading(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="loginPage">
        <div className='container'>
          {isNewPasswordRequired ? (
            <>
              <TextField
                className='inputComponent'
                label="New Password"
                type="password"
                variant="standard"
                onChange={(e) => setNewPassword(e.target.value)}
                value={newPassword}
                disabled={isLoading}
              />
              <TextField
                className='inputComponent'
                label="Confirm New Password"
                type="password"
                variant="standard"
                onChange={(e) => setConfirmNewPassword(e.target.value)}
                value={confirmNewPassword}
                disabled={isLoading}
              />
              <TextField
                className='inputComponent'
                label="Name"
                variant="standard"
                onChange={(e) => setName(e.target.value)}
                value={name}
                disabled={isLoading}
              />
              <Button variant="contained" style={{ backgroundColor: '#009E4E', color: 'black', marginTop: '1rem' }} onClick={handleNewPasswordSubmit} disabled={!isValidNewPassword || isLoading}>
                {isLoading ? <CircularProgress color="inherit" size={24} /> : "Submit"}
              </Button>
            </>
          ) : (
            <>
              <TextField
                className='inputComponent'
                label="Username"
                variant="standard"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                disabled={isLoading}
              />
              <TextField
                className='inputComponent'
                label="Password"
                type="password"
                variant="standard"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                disabled={isLoading}
              />
              <Button variant="contained" style={{ backgroundColor: '#009E4E', color: 'black', marginTop: '1rem' }} onClick={handleLogin} disabled={!isValidLogin || isLoading}>
                {isLoading ? <CircularProgress color="inherit" size={24} /> : "Login"}
              </Button>
            </>
          )}
          {
            error &&
            <Snackbar open={true} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
              <Alert severity="error" sx={{ width: '100%' }}>
                {error}
              </Alert>
            </Snackbar>
          }
{/* 
          {
            isAuthenticated && (
              <Snackbar open={true} autoHideDuration={3000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} onClose={() => {
                navigate("/panel")
              }}>
                <Alert severity="success" sx={{ width: '100%' }}>
                  Logged in. You will be redirected
                </Alert>
              </Snackbar>
            )
          } */}
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;