import { ToastPosition, toast } from "react-toastify";
import { RequestResponse, ResponseType } from "../types/response.types";
import {
  Product,
  ProductRefinerResponse,
  ProductStatus,
  UserLocation,
  User,
  ManagementProduct,
} from "../types/types";
import { CLOUDFRONT_URL } from "./Configuration";

export const FormatDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  };

  return <span>{new Intl.DateTimeFormat("en-US", options).format(date)}</span>;
};

export const ProcessProductData = (data: any): Product | null => {
  try {
    // Check if the required properties exist
    if (!data) return null;

    const backendProduct = data.product;
    const {User: user} = backendProduct

    // Extracting and transforming data
    const PK: string = backendProduct.PK;
    const SK: string = backendProduct.SK;
    const title: string = backendProduct.Product_Title;
    const seller: User = {
      PK: user.PK,
      SK: user.SK,
      sub: user.sub,
      fullName: user.fullName,
      email: user.email,
      joinDate: new Date(user.CreatedAt),
      username: user.GSI1PK,
      phoneNumber: user.phoneNumber,
      lastModifiedDate: new Date(user.LastModifiedDate),
      gender: user.gender,
      picture: user.picture
    };

    const location: UserLocation = new UserLocation(
      backendProduct.UserLocation.name,
      backendProduct.UserLocation.coordinates
    );
    const createdAt: Date = new Date(backendProduct.CreatedAt);
    const lastModifiedAt: Date | null = backendProduct.LastModified_Date
      ? new Date(backendProduct.Last_Modified_Date)
      : null;
    const condition: "New" | "Old" = backendProduct.Product_Condition as
      | "New"
      | "Old";
    const description: string | undefined = backendProduct.Product_Description;
    const images: string[] = (backendProduct.Product_Images || []).map(
      (e: string) => CLOUDFRONT_URL + encodeURL(e)
    );
    const price: number = backendProduct.Product_Price || 0;
    const productUsage: string | undefined = backendProduct.Product_Usage;
    const status: ProductStatus = ProductStatus.PENDING;
    let refiner: ProductRefinerResponse;
    
    if (data.refiner.success === true) {
      refiner = {
        success: data.refiner.success,
        message: {
          categories: data.refiner.message.categories || [],
          brands: data.refiner.message.brands || [],
        }
      };
    } else {
      refiner = {
        success: data.refiner.success,
        message: data.refiner.message,
      };
    }
    
    const product: Product = {
      PK,
      title,
      seller,
      location,
      createdAt,
      lastModifiedAt,
      condition,
      description,
      images,
      price,
      productUsage,
      status,
      SK
    }

    return product;
  } catch (error: any) {
    return null;
  }
}

export function encodeURL(url: string): string {
  return url
    .split("/")
    .map((part) => encodeURIComponent(part))
    .join("/");
}

type AlertMessages = {
  successMessage: string;
  warningMessage: string;
  errorMessage: string;
}

const position: ToastPosition = toast.POSITION.BOTTOM_LEFT;

export const ShowAlert = (response: RequestResponse, alertMessage: AlertMessages) => {

  if (response.SuccessSeverity === ResponseType.SUCCESS) {
    toast.success(alertMessage.successMessage, {
      position,
      autoClose: 5000,
    });
  } else if (response.SuccessSeverity === ResponseType.WARNING) {
    console.log(response.message);
    toast.warning(alertMessage.warningMessage, {
      position,
      autoClose: 7000,
    });
  } else if (response.SuccessSeverity === ResponseType.ERROR) {
    toast.error(`${alertMessage.warningMessage} ${response.message}`, {
      position
    });
  }
}