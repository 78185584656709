import { Auth, Hub } from 'aws-amplify';
import { useEffect, useState } from 'react';
import { UserRole } from '../types/types';

export function useAuth() {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRoles, setUserRoles] = useState<UserRole[]>([]);
    const [user, setUser] = useState<any>(null);
    const [isAuthLoading, setIsAuthLoading] = useState(true);
    
    const signIn = async (username: string, password: string) => {
        setIsAuthLoading(true);
        try {
            const user = await Auth.signIn(username, password);
            setUser(user);
            setIsAuthLoading(false);
            return user;  
        } catch (error) {
            console.error("Error during sign in", error);
            setUser(null);
            setIsAuthLoading(false);
            throw error;  // Throwing the error again to let the caller handle it.
        }
    };

    useEffect(() => {
        (async () => {
            setIsAuthLoading(true);
            try {
                const authenticatedUser = await Auth.currentAuthenticatedUser();
                setUser(authenticatedUser);
            } catch {
                setUser(null);
            }
            setIsAuthLoading(false);
        })();
    }, []);

    useEffect(() => {
        if (user) {
            setIsAuthenticated(true);
            setUserRoles(user.signInUserSession.accessToken.payload['cognito:groups']);
        } else {
            setIsAuthenticated(false);
            setUserRoles([]);
        }
    }, [user]);

    useEffect(() => {
        const listener = Hub.listen('auth', (data: any) => {
            const { payload } = data;
            switch (payload.event) {
                case 'signIn':
                case 'autoSignIn':
                case 'tokenRefresh':
                    if (payload.signInUserSession) {
                        setUser(payload.signInUserSession);
                    }
                    break;
                case 'signOut':
                case 'signIn_failure':
                case 'tokenRefresh_failure':
                case 'autoSignIn_failure':
                case "cognitoHostedUI_failure":
                    setUser(null);
                    break;
            }
        });

        return () => listener()
    }, []);

    return { isAuthenticated, userRoles, signIn, user, isAuthLoading };
}
